import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Page from '@/components/layout/Page';
export const _frontmatter = {
  "title": "Double Turn Patch Notes"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Page;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`v1.1 - Mayhem`}</h2>
    <h3>{`v1.1.2 - 6/13/2021`}</h3>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Add options menu to pause menu during matches`}</li>
      <li parentName="ul">{`Leaderboards`}
        <ul parentName="li">
          <li parentName="ul">{`Add Steam Top 10 to all leaderboards in-game`}</li>
          <li parentName="ul">{`Profile name saved in local leaderboard entries`}</li>
          <li parentName="ul">{`Cleaned up leaderboard menus and UI`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Visual Options`}
        <ul parentName="li">
          <li parentName="ul">{`Adjustable screenshake intensity (Off, Low, Normal, High, Max)`}</li>
          <li parentName="ul">{`Option to disable animated crowds in stadium`}</li>
          <li parentName="ul">{`Option to disable background and lights in stadium`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Audio Options`}
        <ul parentName="li">
          <li parentName="ul">{`Add separate volume controls for sound effects, menu music, and entrance music`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix duplicate local leaderboard entries from being created when repeatedly opening and closing leaderboards after a solo match`}</li>
    </ul>
    <h3>{`v1.1.1 - 5/13/2021`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix bug where devices in player slots 2-4 are permanently disconnected when switching from Versus to Solo matches`}</li>
      <li parentName="ul">{`Allow Select and Guide buttons to be used instead of Start button for gamepads that do not have Start`}</li>
    </ul>
    <h3>{`v1.1.0 - 2/11/2021`}</h3>
    <p>{`Features`}</p>
    <ul>
      <li parentName="ul">{`New Versus Match Type: Mayhem`}
        <ul parentName="li">
          <li parentName="ul">{`Timed match where a steel cage, ladders, and tables rotate every 30 seconds`}</li>
        </ul>
      </li>
      <li parentName="ul">{`New Versus Stadium Variant: Extreme Brawl`}
        <ul parentName="li">
          <li parentName="ul">{`An arena filled with 3 tables, 4 ladders, and a cage`}</li>
          <li parentName="ul">{`Fight inside — and outside — the cage`}</li>
        </ul>
      </li>
      <li parentName="ul">{`New Solo Mode: Hardcore Gauntlet`}
        <ul parentName="li">
          <li parentName="ul">{`Face 3 opponents back-to-back each in a different hardcore match`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Improved AI that has the ability to fight on all "hardcore" variants — Ladders, Tables, Cages, and Scaffolds`}</li>
      <li parentName="ul">{`Reorganized Leaderboards page and added link to Leaderboards on Steam`}</li>
    </ul>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix bug where grabbed opponents would fall during the grab if they were over the edge of a platform`}</li>
      <li parentName="ul">{`Fix bug allowing players to jump out of being stunned`}</li>
      <li parentName="ul">{`Fixed AI getting stuck when opponent stands on ring post and turnbuckle`}</li>
    </ul>
    <h2>{`v1.0 - Full Release`}</h2>
    <h3>{`v1.0.5 - 12/22/2020`}</h3>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`New Palettes: New holiday themed palette for all characters`}
        <ul parentName="li">
          <li parentName="ul">{`The Brawler: Ho Ho Ho (red/white)`}</li>
          <li parentName="ul">{`The High Flyer: Jolly (red/white)`}</li>
          <li parentName="ul">{`The Striker: Frostbite (light blue)`}</li>
          <li parentName="ul">{`The Showman: Festive (greed/red)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Add profile selection and editing to Options/Controls menu`}</li>
      <li parentName="ul">{`Add profile deletion`}</li>
      <li parentName="ul">{`Add AI enable/disable toggle to Practice mode`}</li>
    </ul>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix dodge trail animation for AI opponents`}</li>
      <li parentName="ul">{`Fix signature trail animation for AI opponents`}</li>
      <li parentName="ul">{`Fix dodge trail animation bug when opponent is signatured while dodging`}</li>
    </ul>
    <h3>{`v1.0.4 - 11/20/2020`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Profiles: Fix loading of existing saved profiles`}</li>
    </ul>
    <h3>{`v1.0.3 - 11/11/2020`}</h3>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Stun wrestlers when they signature each other at the same time`}</li>
    </ul>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix bug where wrestler frozen mid-animation when striked while already grabbed`}</li>
      <li parentName="ul">{`Fix bug where fallen wrestler floats or makes other unintentional animations or physics oddities`}
        <ul parentName="li">
          <li parentName="ul">{`Prevent grabbing an opponent who is already signatured or fallen`}</li>
          <li parentName="ul">{`Prevent striking an opponent who is already signatured or fallen`}</li>
          <li parentName="ul">{`Prevent actions during signature animation`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`v1.0.2 - 11/09/2020`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Training mode: Fix pause button issues while training help text is animating`}</li>
    </ul>
    <h3>{`v1.0.1 - 11/06/2020`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Training mode: Fix AI to block and strike properly while training`}</li>
    </ul>
    <h3>{`v1.0.0 - 11/06/2020`}</h3>
    <p>{`Features`}</p>
    <ul>
      <li parentName="ul">{`Training mode`}</li>
      <li parentName="ul">{`Steam achievements`}</li>
      <li parentName="ul">{`Steam cloud saves & multiple account support`}</li>
      <li parentName="ul">{`Steam leaderboards`}
        <ul parentName="li">
          <li parentName="ul">{`Fastest Gauntlet match`}</li>
          <li parentName="ul">{`Fastest Battle Royal match`}</li>
          <li parentName="ul">{`Most Battle Royal falls`}</li>
          <li parentName="ul">{`Fastest Perfect Battle Royal match`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Brand new AI behavior`}</li>
      <li parentName="ul">{`Rebalance Battle Royal`}
        <ul parentName="li">
          <li parentName="ul">{`AI Opponents can target other opponents`}</li>
          <li parentName="ul">{`20 falls no longer required to win, just survival`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Animation bugs`}
        <ul parentName="li">
          <li parentName="ul">{`Brawler DDT: fix timing to ensure it hits the ground`}</li>
          <li parentName="ul">{`High Flyer strike: fix kick alignment with opponent`}</li>
          <li parentName="ul">{`High Flyer Dropkick: fix sprite overlap with opponent`}</li>
          <li parentName="ul">{`High Flyer Moonsault: fix opponent collapse animation timing`}</li>
          <li parentName="ul">{`Showman Powerbomb: fix timing to ensure it hits the ground`}</li>
          <li parentName="ul">{`Showman Powerbomb: fix height of opponent during lift`}</li>
          <li parentName="ul">{`All character finishers: fix dazed animation`}</li>
          <li parentName="ul">{`All character dodge: fix colors with alternate palettes during dodge`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Use correct device button images for signatures in-match`}</li>
      <li parentName="ul">{`Fix colors for in-game player portaits with alternate color palettes`}</li>
    </ul>
    <h2>{`v0.20 - Combat Update`}</h2>
    <h3>{`v0.20.0 - 09/20/2020`}</h3>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Rework and rebalance signaures`}
        <ul parentName="li">
          <li parentName="ul">{`Increase startup time (~33% longer)`}</li>
          <li parentName="ul">{`Shorter distance travelled (~40% shorter distance)`}</li>
          <li parentName="ul">{`Faster dash speed (~30% faster)`}</li>
          <li parentName="ul">{`Getting signatured with full heat only drops to 50% on respawn`}</li>
          <li parentName="ul">{`A missed signature drops heat to 75% of the max`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Environmental stuns`}
        <ul parentName="li">
          <li parentName="ul">{`Stun when thrown onto table`}</li>
          <li parentName="ul">{`Stun when break through table`}</li>
          <li parentName="ul">{`Stun when thrown off scaffold or ladder`}</li>
          <li parentName="ul">{`Stun when downward strike off of scaffold or ladder`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`v0.19 - Profiles & Palettes`}</h2>
    <h3>{`v0.19.1 - 07/23/2020`}</h3>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Minor AI tweaks to make the AI less difficult`}</li>
      <li parentName="ul">{`Increase spawn timers between opponents in Battle Royal`}</li>
      <li parentName="ul">{`Add random alternate palettes to the opponents with AI`}</li>
      <li parentName="ul">{`Show controls before all solo matches`}</li>
    </ul>
    <h3>{`v0.19.0 - 07/21/2020`}</h3>
    <p>{`Features`}</p>
    <ul>
      <li parentName="ul">{`Player Profiles`}
        <ul parentName="li">
          <li parentName="ul">{`Allow players to create a profile and name it to save control configs as they like them`}</li>
          <li parentName="ul">{`Automatically create a profile for each logged in Steam user, by default`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Alternate character color palettes`}</li>
    </ul>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Pause match when player device disconnects`}</li>
      <li parentName="ul">{`Add new keyboard button images to the menus and UI`}</li>
      <li parentName="ul">{`Show correct connected gamepad's buttons in menu UI (or Keyboard if unavailable)`}</li>
      <li parentName="ul">{`Simplify custom controls editing`}</li>
      <li parentName="ul">{`Add some common controller and keyboard preset configs`}</li>
    </ul>
    <p>{`Bug Fixes`}</p>
    <ul>
      <li parentName="ul">{`Scrolling on controls editor with a gamepad after editing breaks the list`}</li>
      <li parentName="ul">{`[Windows]`}{` iBuffalo SNES controller DPAD not working in combat`}</li>
      <li parentName="ul">{`Rebinding DPAD breaks Left Analog stick movement (and vise versa)`}</li>
    </ul>
    <h2>{`v0.18 - Core Combat Update`}</h2>
    <h3>{`v0.18.0 - 06/22/2020`}</h3>
    <p>{`Features`}</p>
    <ul>
      <li parentName="ul">{`New Versus Match Type: Scramble`}
        <ul parentName="li">
          <li parentName="ul">{`Players fight for control of the championship title in a set timeframe.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Combat rebalancing`}
        <ul parentName="li">
          <li parentName="ul">{`Reduce movement for strikes`}</li>
          <li parentName="ul">{`Increase strike heat`}</li>
          <li parentName="ul">{`Increase block heat`}</li>
          <li parentName="ul">{`Double/combo strikes only works if the first hits`}</li>
          <li parentName="ul">{`Make all horizontal strikes have a small vertical stagger`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Improved hit feedback and VFX`}
        <ul parentName="li">
          <li parentName="ul">{`Show heat gained by performing successful moves`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Increased arena size outside of ring w/ scrolling camera`}</li>
    </ul>
    <h2>{`v0.17 - Remote Play Together`}</h2>
    <h3>{`v0.17.1 - 06/13/2020`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`[macOS]`}{` Fix custom controls editing in pre-match and mid-match pause menu`}</li>
    </ul>
    <h3>{`v0.17.0 - 04/20/2020`}</h3>
    <p>{`Features`}</p>
    <ul>
      <li parentName="ul">{`Disable online and servers`}</li>
    </ul>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Add PS4 controller button images`}</li>
      <li parentName="ul">{`Add controls for audio adjustment in Options`}</li>
    </ul>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix missing gamepad buttons on main menu`}</li>
      <li parentName="ul">{`Fix animation layering bug during finishers`}</li>
      <li parentName="ul">{`[macOS]`}{` Fix render issues with multiple monitors and High-DPI resolutions`}</li>
    </ul>
    <h2>{`v0.16 - The Controls Update`}</h2>
    <h3>{`v0.16.1 - 05/08/2019`}</h3>
    <p>{`Improvements`}</p>
    <ul>
      <li parentName="ul">{`Flipped the controls list so action names are on the left and buttons are on the right`}</li>
    </ul>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix crash when using Steam Controller or Steam Input`}</li>
      <li parentName="ul">{`Fix unbound actions and buggy button binding UI`}</li>
    </ul>
    <h3>{`v0.16.0 - 06/20/2018`}</h3>
    <p>{`Features`}</p>
    <ul>
      <li parentName="ul">{`Add support for Steam Controller`}</li>
      <li parentName="ul">{`Add support for custom control bindings`}</li>
    </ul>
    <h2>{`v0.15 - Early Access Release`}</h2>
    <h3>{`v0.15.6 - 05/01/2018`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix broken opponent UI in Gauntlet mode for 2nd and 3rd opponent`}</li>
    </ul>
    <h3>{`v0.15.5 - 05/30/2018`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Prevent strikes from hitting multiple AI opponents at once`}</li>
      <li parentName="ul">{`Fix AI getting stuck at ropes`}</li>
      <li parentName="ul">{`Reduce Battle Royal difficulty by increasing all timers by 5 seconds`}</li>
      <li parentName="ul">{`Fix memory bloat problems related to the hit vfx causing crashes for some users`}</li>
      <li parentName="ul">{`Fix periodic crash when leaving online menu state`}</li>
    </ul>
    <h3>{`v0.15.4 - 04/23/2018`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix ping calculation when connecting or reconnecting to region servers away during signatures/finishers`}</li>
      <li parentName="ul">{`Add "Press A/Enter" prompt to results screen to improve UX`}</li>
      <li parentName="ul">{`Fix visual bug where AI opponents fly away during signatures/finishers`}</li>
    </ul>
    <h3>{`v0.15.3 - 04/18/2018`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Fix online match inconsistency`}</li>
    </ul>
    <h3>{`v0.15.2 - 04/13/2018`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Hotfix to suppress a UI related crash`}</li>
    </ul>
    <h3>{`v0.15.1 - 04/13/2018`}</h3>
    <p>{`Bug fixes`}</p>
    <ul>
      <li parentName="ul">{`Idle AI in Practice to make it easier to learn how to play`}</li>
      <li parentName="ul">{`Prevent signature move while grabbing/throwing/striking`}</li>
      <li parentName="ul">{`Fixed button display issue, was showing LT instead of LB in controls panel`}</li>
    </ul>
    <h3>{`v0.15.0 - 04/11/2018`}</h3>
    <p>{`Features`}</p>
    <ul>
      <li parentName="ul">{`Finishers for each character`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      